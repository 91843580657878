<script setup lang="ts">
import type { Promotion } from "@/types";
const { t } = useT();
const { open } = useNlcModals();

defineProps<{
	title: string;
	subTitle: string;
	type?: Promotion["type"];
	image: string;
	reasonTime?: boolean;
	dateDailyWheel: string;
}>();

const handleClick = () => {
	open("LazyOModalDailyWheel");
};
</script>

<template>
	<MPromotionWrapper
		:type="type"
		:title="title"
		:image="image"
		:buttonName="reasonTime ? '' : t('Spin the Wheel')"
		buttonTid="promo-daily-wheel"
		@click-card="handleClick"
	>
		<template #top>
			<div class="wrapper-wheel flex-center">
				<NuxtImg
					class="coins"
					:src="`/nuxt-img/promotions/coins.png`"
					alt="coins"
					width="252"
					height="159"
					format="avif"
					loading="lazy"
				/>
				<MDailyWheel class="bg-wheel" />
			</div>
		</template>

		<AText v-if="subTitle" :size="22" class="subtitle" :modifiers="['bold', 'center', 'uppercase']">
			{{ subTitle }}
		</AText>

		<template #bottom>
			<MCounterBadgeGroup v-if="reasonTime" :timestamp="dateDailyWheel ?? ''" :badgeText="t('Next in')" />
		</template>
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
.card-dailyWheel {
	background: radial-gradient(120.89% 92.38% at 50% 9.4%, #d021fc 13.09%, #53117c 50.49%, #300d58 72.25%, #1e0b46 100%);

	&:deep(> img) {
		display: none;
	}

	.bg-wheel {
		position: relative;
		z-index: -1;
		width: 140px;
		height: 145px;
	}
}

.wrapper-wheel {
	position: relative;
	margin: 4px 0 -10px;

	.coins {
		position: absolute;
		z-index: 1;
	}
}

.subtitle {
	padding: 0 16px;
	text-shadow: 0px 11px 18px rgba(var(--neutral-0-rgb), 0.25);
}
</style>
